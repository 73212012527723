import {
  Button,
  InstitutionAccountsWrapper,
  NavigationWrapper,
  PickerHOC,
  TargetsWrapper,
  Text,
  UiWrapper,
  View,
} from '@coinscrap/webapp-core';

import Footer from 'common/Footer';
import HeaderRedPrimary from 'common/HeaderRedPrimary';

import colors from 'config/colors';
import routes from 'config/routes';
import { OTP_ACTIONS } from 'libs/common';
import React, { useState } from 'react';
import Policy from './components/Policy';

const SelectPolicy = PickerHOC(
  {
    parentTarget: {
      arrayItemMatcher: {
        allowNoExistence: true,
        itemPropertyPath: 'level',
        type: 'value',
        value: 0,
      },
      sourcePath: 'targets',
      sourceWrapper: TargetsWrapper,
    },
    userTargets: {
      arrayItemFilter: {
        itemPropertyPath: 'level',
        type: 'value',
        value: 1,
      },
      sourcePath: 'targets',
      sourceWrapper: TargetsWrapper,
    },
    generaliInstitution: {
      arrayItemMatcher: {
        itemPropertyPath: 'institutionIdentifier',
        value: 'generali',
        type: 'value',
      },
      sourcePath: 'institutionAccounts',
      sourceWrapper: InstitutionAccountsWrapper,
    },
  },
  () => {
    UiWrapper.use().useLoading(true);
    return null;
  },
)(() => {
  const { useFooter, useHeader, useLoading } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();
  const { generaliInstitution, parentTarget } = PickerHOC.use();
  const [selected, setSelected] = useState(parentTarget?.creationData?.destProduct?.combinedId);

  useLoading(!generaliInstitution?.products);

  const policiesOfUser = generaliInstitution?.products || [];

  useFooter(
    <Footer style={{ backgroundColor: colors.white, marginBottom: 20 }}>
      <Button
        style={{ marginTop: 20 }}
        borderWhiteButtonSt
        disabled={!selected}
        onClick={() => {
          navigateTo(routes.otpSms, {
            queryParams: {
              from: {
                name: routes.createGlobalTarget,
                routeParams: {
                  institutionAccountId: selected.split('-')[0],
                  productId: selected.split('-')[1],
                },
              },
              action: OTP_ACTIONS.SELECT_POLICY,
              payload: {
                targetId: parentTarget?.id,
                institutionAccountId: selected.split('-')[0],
                productId: selected.split('-')[1],
              },
            },
          });
        }}
      >
        Siguiente
      </Button>
    </Footer>,
    [selected],
  );

  useHeader(
    <View>
      <HeaderRedPrimary />
    </View>,
    [],
  );

  console.log({ policiesOfUser });
  return (
    <View style={{ marginTop: 40 }}>
      <Text font27St>Selecciona tu póliza</Text>
      <Text textCenterSt lineHeight16St grey1St font15St style={{ marginTop: 8, padding: 10 }}>
        Selecciona la póliza en la cual se van a crear los objetivos y aportaciones.
      </Text>
      <View style={{ marginTop: 40 }}>
        {!policiesOfUser.length && (
          <Text textCenterSt lineHeight16St grey1St font15St style={{ marginTop: 8, padding: 20 }}>
            Ponte en contacto con tu soporte al cliente para solucionar el problema.
          </Text>
        )}
        {policiesOfUser
          .filter(
            (policy) => policy?.identifier.slice(0, 3) === '90G',

            // || policy?.identifier.slice(0, 3) === '86G',
          )
          .map((p) => (
            <Policy
              onSelect={() => setSelected(p.institutionAccountId + '-' + p.id)}
              active={selected === p.institutionAccountId + '-' + p.id}
              name={p.name}
              balance={p.balance}
              dark
            />
          ))}
      </View>
    </View>
  );
});

export default SelectPolicy;
